/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Mulish_200ExtraLight = require('./Mulish_200ExtraLight.ttf');
export const Mulish_300Light = require('./Mulish_300Light.ttf');
export const Mulish_400Regular = require('./Mulish_400Regular.ttf');
export const Mulish_500Medium = require('./Mulish_500Medium.ttf');
export const Mulish_600SemiBold = require('./Mulish_600SemiBold.ttf');
export const Mulish_700Bold = require('./Mulish_700Bold.ttf');
export const Mulish_800ExtraBold = require('./Mulish_800ExtraBold.ttf');
export const Mulish_900Black = require('./Mulish_900Black.ttf');
export const Mulish_200ExtraLight_Italic = require('./Mulish_200ExtraLight_Italic.ttf');
export const Mulish_300Light_Italic = require('./Mulish_300Light_Italic.ttf');
export const Mulish_400Regular_Italic = require('./Mulish_400Regular_Italic.ttf');
export const Mulish_500Medium_Italic = require('./Mulish_500Medium_Italic.ttf');
export const Mulish_600SemiBold_Italic = require('./Mulish_600SemiBold_Italic.ttf');
export const Mulish_700Bold_Italic = require('./Mulish_700Bold_Italic.ttf');
export const Mulish_800ExtraBold_Italic = require('./Mulish_800ExtraBold_Italic.ttf');
export const Mulish_900Black_Italic = require('./Mulish_900Black_Italic.ttf');
